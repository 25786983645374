$primary: #C4161D;
$secondary: #262626;
$tertiary: #F9A72B;

$success: #63b160;
$danger: #fa6557;
$warning: #efa755;

/* these are non- bootstrap variables. */
$content-bg: #FFF;
$list-bg: #FFF;
$navbar-bg: $secondary;

$sidebar-bg: #ffffff;
$sidebar-highlight: #232323;
$sidebar-selected: $tertiary;
$sidebar-selected-bg: #464646;
/* /these are non- bootstrap variables. */

$body-bg: lighten($secondary, 81%);
$body-color: #232832;
$input-placeholder-color: #cbcbcb;
$headings-color: #1f232b;

$form-label-color: #586071;
$form-label-font-weight: 700;
$form-label-font-size: 14px;
$form-label-margin-bottom: 0.4rem;


$input-bg: #fafafa;
$input-border-color: #e3e3e3;
$input-border-width: 1px;
$input-focus-bg: #FFF;

$accordion-bg: $content-bg;
$card-bg: $content-bg;
$list-bg: $content-bg;
$list-group-bg: $content-bg;

$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: darken($primary, 10%);
$dropdown-link-hover-color: #fff;

$border-radius:    3px;
$border-radius-lg: 3px;
$border-radius-sm: 3px;

$input-padding-x: 0.5rem;
$input-padding-y: 0.3rem;

$input-btn-padding-x: 0.7rem;
$input-btn-padding-y: 0.3rem;

$input-font-size: 0.95rem;
$input-btn-font-size: 0.95rem;

$font-family-base: 'Roboto Condensed';
$font-family-sans-serif: 'Roboto Condensed';
$headings-font-family: 'Roboto Condensed';

$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 2px;

$enable-responsive-font-sizes: true;

$table-hover-bg: #ececec;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
